<template>
  <!--
    The view for the CustomerNotification-component.
    Used to display all customer notification process.
  -->
  <Portlet 
    :title="$t('customerNotification.process')" 
    icon="envelope-open-text" 
    class="customerNotification"
  >
    <CustomerNotificationProcess />
  </Portlet>
</template>

<script>
export default {
  name: "CustomerNotification",
  components: {
    CustomerNotificationProcess: () => import('@/components/CustomerNotification/CustomerNotificationProcess.vue')
  },
  metaInfo () {
    return {
      title: this.$t('menu.customerNotification')
    }
  }
}
</script>